import React, { useEffect } from "react";
import { Box, Button, Typography } from "../../Components";
import { IMAGES } from "../../Assets";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { ROUTER_PATH } from "../../config";
import { getDoctorProfile } from "../../Redux/actions";
import { Col, Row } from "react-bootstrap";

export default function ChatCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [gender, setGender] = useState(null);
  const { state } = useLocation();

  // const userId = useSelector((state) => state.users);
  // console.log("userId", userId?.GET_USER_ID_DATA?.data, gender);

  // console.log("state", state);
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  const handleGetUserId = (gender) => {
    // dispatch(getUserId());
    dispatch(getDoctorProfile({ isSystemDoctor: true }));
    navigate(ROUTER_PATH.PATIENT_DIAGNOSIS, {
      state: { gender: gender, vitals: state?.vitals },
    });
  };
  // useEffect(() => {
  //   if (gender) {
  //     // if (userId?.GET_USER_ID_DATA?.data && gender) {
  //     // localStorage.setItem("userId", userId?.GET_USER_ID_DATA?.data);

  //   }
  // }, []);

  return (
    <div className="container">
      <Row>
        <Col
          lg={5}
          md={6}
          width="100%"
          maxWidth="35rem"
          borderRadius="1.375rem"
          boxShadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09);"
          className="mx-auto my-5"
        >
          <Box
            width="100%"
            background="#ffffff"
            className="d-flex justify-content-center"
          >
            <img
              src={IMAGES.FEMALE_CHARACTER}
              alt="female"
              className="img-fluid vh-50"
            />
          </Box>

          <Box
            width="100%"
            // background="var(--grad, linear-gradient(113deg, #53972C 0%, #ACDD59 100%))"
            className="modal-body-content"
            background="transparent"
          >
            <div className="position-relative p-5">
              <img
                src={IMAGES.CARD_BACKGROUND}
                alt="background"
                className="card-bg"
              />
              <Typography color="#ffffff" as="h1" align="center">
                Hello!
              </Typography>

              <Typography
                color="#ffffff"
                fontSize="1.8rem"
                align="center"
                fontWeight="700"
              >
                I’m Doctor Elizabeth
              </Typography>

              <Typography
                color="#ffffff"
                as="p"
                align="center"
                className="my-3"
              >
                Your AI powered Doctor. I’ll assist you in diagnosing your
                problem by asking step-by-step questions through chat.
              </Typography>

              <div className="d-flex justify-content-center">
                <Button
                  width="15rem"
                  backgroundColor="#fff"
                  onClick={() => {
                    // setGender("female");
                    handleGetUserId("female");
                  }}
                >
                  {/* {userId?.GET_USER_ID_LOADING ? (
                  <GenericLoader animation="border" />
                ) : ( */}
                  <Typography
                    fontSize="1rem"
                    fontWeight="700"
                    align="center"
                    color="#4F2883"
                  >
                    Let's Start Consultation
                  </Typography>
                  {/* )} */}
                </Button>
              </div>
            </div>
          </Box>
        </Col>

        <Col
          lg={5}
          md={6}
          width="100%"
          maxWidth="35rem"
          borderRadius="1.375rem"
          boxShadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09);"
          className="mx-auto my-5"
        >
          <Box
            width="100%"
            background="#ffffff"
            className="d-flex justify-content-center"
          >
            <img
              src={IMAGES.MALE_CHARACTER}
              alt="male"
              className="img-fluid vh-50"
            />
          </Box>

          <Box
            width="100%"
            background="transparent"
            className="modal-body-content"
          >
            <div className="position-relative p-5">
              <img
                src={IMAGES.CARD_BACKGROUND}
                alt="background"
                className="card-bg"
              />
              <Typography color="#ffffff" as="h1" align="center">
                Hello!
              </Typography>

              <Typography
                color="#ffffff"
                fontSize="1.8rem"
                align="center"
                fontWeight="700"
              >
                I’m Doctor William
              </Typography>

              <Typography
                color="#ffffff"
                as="p"
                align="center"
                className="my-3"
              >
                Your AI powered Doctor. I’ll assist you in diagnosing your
                problem by asking step-by-step questions through chat.
              </Typography>

              <div className="d-flex justify-content-center">
                <Button
                  width="15rem"
                  backgroundColor="#fff"
                  onClick={() => {
                    // setGender("male");
                    handleGetUserId("male");
                  }}
                >
                  {/* {userId?.GET_USER_ID_LOADING ? (
                  <GenericLoader animation="border" />
                ) : ( */}
                  <Typography
                    fontSize="1rem"
                    fontWeight="700"
                    align="center"
                    color="#4F2883"
                  >
                    Let's Start Consultation
                  </Typography>
                  {/* )} */}
                </Button>
              </div>
            </div>
          </Box>
        </Col>
      </Row>
    </div>
  );
}

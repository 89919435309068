import { useFormik } from "formik";
import * as Yup from "yup";
import { API_KEYS, ROUTER_PATH } from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
	Button,
	CheckBox,
	GenericLoader,
	InputField,
	Toaster,
	Typography,
} from "../../../Components";
import { IMAGES } from "../../../Assets";
import { InputAdornment } from "@mui/material";
import { MdLockOutline, MdMailOutline } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUp, socialMediaLogInAction } from "../../../Redux/actions";
import { LoginSocialGoogle } from "reactjs-social-login";

export default function SignUp() {
	const [showPassword, setShowPassword] = useState(false);
	const [confirmShowPassword, setConfirmShowPassword] = useState(false);
	const navigate = useNavigate();
	const SIGN_UP_SCHEMA = Yup.object().shape({
		name: Yup.string().required("Please enter your name"),
		email: Yup.string()
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				"Please enter a valid email address"
			)
			.required("Please enter your email address"),
		password: Yup.string()
			.required("Please enter your password")
			.min(8, "Password is too short - should be 8 chars minimum.")
			.matches(/[a-z]+/, "One lowercase character")
			.matches(/[A-Z]+/, "One uppercase character")
			.matches(/[@$!%*#?&]+/, "One special character")
			.matches(/\d+/, "One number"),
		passwordConfirmation: Yup.string()
			.required("Please confirm your password")
			.oneOf([Yup.ref("password"), null], "Passwords must match"),
	});

	const user = useSelector((state) => state.users);

	const dispatch = useDispatch();

	const moveToNext = (response) => {
		if (response?.data?.data) {
			navigate(ROUTER_PATH.SIGN_IN);

			// {     state: { name: formik?.values?.name },
			// });
			// localStorage.setItem(
			//   "test",
			//   JSON.stringify({ x: response?.data?.data, y: formik?.values?.name })
			// );
		}
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			password: "",
			passwordConfirmation: "",
		},
		validationSchema: SIGN_UP_SCHEMA,
		onSubmit: (values) => {
			if (values?.email && values?.password && values?.name) {
				dispatch(
					signUp(
						{
							name: values?.name,
							email: values?.email,
							password: values?.password,
							roleId: 3,
						},
						moveToNext
					)
				);
			} else {
				Toaster({ message: "Email or Password is wrong!", type: "error" });
			}
		},
	});
	const onLoginStart = useCallback(() => {
		console.log("login start");
	}, []);

	const moveToAssessmentForm = (response) => {
		console.log("response", response?.data?.data);
		navigate(ROUTER_PATH.WELCOME, {
			state: {
				name: response?.data?.data?.name,
				userId: response?.data?.data?.userId,
			},
		});
	};
	const moveToHome = (response) => {
		localStorage.setItem("med_user", JSON.stringify(response?.data?.data));
		console.log("response", response?.data?.data);
		navigate(ROUTER_PATH.HOME_PAGE);
	};
	const handleGoogleLogin = ({ provider, data }) => {
		if (data) {
			dispatch(
				socialMediaLogInAction(
					{
						name: data?.name,
						email: data?.email,
						key: data?.sub,
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
						socialMediaTypeId: 1,
					},
					moveToHome,
					moveToAssessmentForm
				)
			);
		}
	};

	// const handleFacebookLogin = ({ provider, data }) => {
	//   if (data) {
	//     dispatch(
	//       socialMediaLogInAction(
	//         {
	//           name: data?.name,
	//           email: data?.email,
	//           key: data?.id,
	//           timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	//           socialMediaTypeId: 2,
	//         },
	//         moveToHome,
	//         moveToAssessmentForm
	//       )
	//     );
	//   }
	// };
	return (
		<div className={`h-screen`}>
			<Row className="m-0 p-5 " style={{ height: "110vh" }}>
				<Col
					md={12}
					lg={8}
					className="position-relative m-0 p-0 d-flex align-items-center justify-content-center d-none d-lg-flex"
				></Col>
				<Col
					md={12}
					lg={4}
					className="m-0 p-0 d-flex align-items-center justify-content-center"
				>
					<div className="mt-5">
						<h1 className="text-white font-poppins-bold ">SIGN UP</h1>
						<form
							onSubmit={formik.handleSubmit}
							className="container-fluid rounded-1 p-0"
						>
							<Row className="m-0 p-0">
								<Col md={12} className="mt-4 p-0">
									<InputField
										type="text"
										backgroundColor="#EBEBFF"
										name="name"
										placeholder="Type your name"
										className="w-100"
										onChange={formik.handleChange}
										startAdornment={
											<InputAdornment position="start">
												<FaRegUser size={20} className="text-light-gray" />
											</InputAdornment>
										}
									/>
									{formik.touched.name && formik.errors.name && (
										<small className="text-danger small">
											{formik.errors.name}
										</small>
									)}
								</Col>{" "}
								<Col md={12} className="mt-4 p-0">
									<InputField
										type="email"
										backgroundColor="#EBEBFF"
										name="email"
										placeholder="Type your email"
										className="w-100"
										onChange={formik.handleChange}
										startAdornment={
											<InputAdornment position="start">
												<MdMailOutline size={20} className="text-light-gray" />
											</InputAdornment>
										}
									/>
									{formik.touched.email && formik.errors.email && (
										<small className="text-danger small">
											{formik.errors.email}
										</small>
									)}
								</Col>
								<Col md={12} className="mt-4 p-0">
									<InputField
										backgroundColor="#EBEBFF"
										type={showPassword ? "text" : "password"}
										name="password"
										placeholder="Type your password"
										className="w-100"
										onChange={formik.handleChange}
										startAdornment={
											<InputAdornment position="start">
												<MdLockOutline size={20} className="text-light-gray" />
											</InputAdornment>
										}
										endAdornment={
											<InputAdornment position="start ps-2 ">
												{showPassword ? (
													<FiEyeOff
														size={20}
														className="text-light-gray cursor-pointer"
														onClick={() => setShowPassword(false)}
													/>
												) : (
													<FiEye
														size={20}
														className="text-light-gray cursor-pointer"
														onClick={() => setShowPassword(true)}
													/>
												)}
											</InputAdornment>
										}
									/>
									{formik.touched.password && formik.errors.password && (
										<small className="text-danger small">
											{formik.errors.password}
										</small>
									)}
								</Col>
								<Col md={12} className="mt-4 p-0">
									<InputField
										backgroundColor="#EBEBFF"
										type={confirmShowPassword ? "text" : "password"}
										name="passwordConfirmation"
										placeholder="Confirm your password"
										className="w-100"
										onChange={formik.handleChange}
										startAdornment={
											<InputAdornment position="start">
												<MdLockOutline size={20} className="text-light-gray" />
											</InputAdornment>
										}
										endAdornment={
											<InputAdornment position="start ps-2 ">
												{confirmShowPassword ? (
													<FiEyeOff
														size={20}
														className="text-light-gray cursor-pointer"
														onClick={() => setConfirmShowPassword(false)}
													/>
												) : (
													<FiEye
														size={20}
														className="text-light-gray cursor-pointer"
														onClick={() => setConfirmShowPassword(true)}
													/>
												)}
											</InputAdornment>
										}
									/>
									{formik.touched.passwordConfirmation &&
										formik.errors.passwordConfirmation && (
											<small className="text-danger small">
												{formik.errors.passwordConfirmation}
											</small>
										)}
								</Col>
								<Col md={12} className="mt-4 p-0">
									<CheckBox
										// className="py-1"
										name="termsAndConditions"
										id="termsAndConditions"
										checked={formik.values.termsAndConditions}
										onChange={(e) => {
											formik.setFieldValue(
												"termsAndConditions",
												e.target.checked === true ? true : ""
											);
										}}
										// onChange={formik.handleChange}
										label={
											<>
												I agree to the{" "}
												<a href="https://medistreamline.com/terms-conditions">
													Terms and Conditions
												</a>
											</>
										}
									/>
								</Col>
								<Col md={12} className="m-0 p-0 mt-4">
									<Button
										type="submit"
										className="w-100 signup-button"
										disabled={!formik.values.termsAndConditions}
									>
										<Typography
											align="center"
											fontSize="1rem"
											color="#fff"
											fontWeight="500"
										>
											{user?.CREATE_PATIENT_IS_LOADING ? (
												<GenericLoader />
											) : (
												"Sign up"
											)}
										</Typography>
									</Button>

									<div className="d-flex my-4">
										{/* <Button
                      className="bg-gray w-100 me-2 d-flex align-items-center justify-content-center"
                      type="button"
                    >
                      <LoginSocialFacebook
                        appId={API_KEYS?.FACEBOOK_APP_ID}
                        onLoginStart={onLoginStart}
                        scope="email"
                        onResolve={handleFacebookLogin}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <Typography
                          align="center"
                          fontWeight="300"
                          fontSize="1rem"
                          color="#fff"
                        >
                          <img
                            src={IMAGES.FB_ICON}
                            alt="facebook"
                            width={25}
                            className="me-2"
                          />
                          Facebook
                        </Typography>
                      </LoginSocialFacebook>
                    </Button> */}
										<Button
											className="signup-button w-100   d-flex align-items-center justify-content-center"
											type="button"
										>
											<LoginSocialGoogle
												client_id={API_KEYS?.GOOGLE_CLIENT_Id}
												scope="openid profile email"
												discoveryDocs="claims_supported"
												onLoginStart={onLoginStart}
												onResolve={handleGoogleLogin}
												onReject={(err) => {
													console.log(err);
												}}
											>
												<Typography
													align="center"
													fontWeight="300"
													fontSize="1rem"
													color="#fff"
													className="d-flex align-items-center"
												>
													<img
														src={IMAGES.GOOGLE_ICON}
														alt="google"
														width={20}
														className="me-2"
													/>
													Continue with Google
												</Typography>
											</LoginSocialGoogle>
										</Button>
									</div>

									<p className="text-white mt-4 w-100 d-flex justify-content-center">
										Already have an account?
										<Link to={ROUTER_PATH.SIGN_IN} className="auth-link ms-2">
											Sign In
										</Link>
									</p>
								</Col>
							</Row>
						</form>
					</div>
				</Col>
			</Row>
		</div>
	);
}

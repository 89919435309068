import { useFormik } from "formik";
import * as Yup from "yup";
import { ROUTER_PATH } from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
  Button,
  GenericLoader,
  InputField,
  Toaster,
  Typography,
} from "../../../Components";
import { IMAGES } from "../../../Assets";
import { InputAdornment } from "@mui/material";
import { MdLockOutline, MdMailOutline } from "react-icons/md";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInAction, socialMediaLogInAction } from "../../../Redux/actions";
import { LoginSocialGoogle } from "reactjs-social-login";
import { API_KEYS } from "../../../config";
import useWindowSizeHook from "../../../Components/WindowSizeHook";

export default function SignIn() {
  const [, setProvider] = useState("");
  const [, setProfile] = useState(null);
  const { width: useWidth } = useWindowSizeHook();
  const onLoginStart = useCallback(() => {
    // console.log("login start");
  }, []);

  // const onLogoutSuccess = useCallback(() => {
  //   setProfile(null);
  //   setProvider("");
  //   console.log("logout success");
  // }, []);

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SIGN_IN_SCHEMA = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please enter your password")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const user = useSelector((state) => state.users);

  // console.log("user", user);
  const moveToNext = (response) => {
    localStorage.setItem("med_user", JSON.stringify(response?.data?.data));
    // console.log("response", response?.data?.data);
    navigate(ROUTER_PATH.HOME_PAGE);
  };
  const moveToAssessmentForm = (response) => {
    // console.log("response", response?.data?.data);
    navigate(ROUTER_PATH.WELCOME, {
      state: {
        name: response?.data?.data?.name,
        userId: response?.data?.data?.userId,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SIGN_IN_SCHEMA,
    onSubmit: (values) => {
      if (values?.email && values?.password) {
        dispatch(
          signInAction(
            {
              email: values?.email,
              password: values?.password,
              timezone: "",
            },
            moveToNext,
            moveToAssessmentForm
          )
        );
      } else {
        Toaster({ message: "Email or Password is wrong!", type: "error" });
      }
    },
  });

  const handleGoogleLogin = ({ provider, data }) => {
    // console.log("login google data:", provider, data);
    setProvider(provider);
    setProfile(data);
    if (data) {
      dispatch(
        socialMediaLogInAction(
          {
            name: data?.name,
            email: data?.email,
            key: data?.sub,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            socialMediaTypeId: 1,
          },
          moveToNext,
          moveToAssessmentForm
        )
      );
    }
  };

  // const handleFacebookLogin = ({ provider, data }) => {
  //   console.log("login Facebook data:", data);
  //   setProvider(provider);
  //   setProfile(data);
  //   if (data) {
  //     dispatch(
  //       socialMediaLogInAction(
  //         {
  //           name: data?.name,
  //           email: data?.email,
  //           key: data?.id,
  //           timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //           socialMediaTypeId: 2,
  //         },
  //         moveToNext,
  //         moveToAssessmentForm
  //       )
  //     );
  //   }
  // };

  return (
    <div>
      <Row
        className={` ${
          useWidth > 600 ? "m-0 p-5 vh-100" : "vh-100 p-3 m-0"
        }    `}
      >
        <Col
          md={12}
          lg={8}
          className="position-relative m-0 p-0 d-flex align-items-center justify-content-center d-none d-lg-flex"
        ></Col>
        <Col md={12} lg={4} className="m-0 p-0 d-flex align-items-center">
          <div>
            <h1 className="text-white font-poppins-bold">SIGN IN</h1>
            <p className="text-white font-poppins-semibold mb-0">
              Sign in with email address
            </p>
            <form
              onSubmit={formik.handleSubmit}
              className="container-fluid rounded-1 p-0"
            >
              <Row className="m-0 p-0">
                <Col md={12} className="mt-4 p-0">
                  <InputField
                    type="email"
                    backgroundColor="#EBEBFF"
                    name="email"
                    placeholder="Type your email"
                    className="w-100"
                    onChange={formik.handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <MdMailOutline size={20} className="text-light-gray" />
                      </InputAdornment>
                    }
                  />
                  {formik.touched.email && formik.errors.email && (
                    <small className="text-danger small">
                      {formik.errors.email}
                    </small>
                  )}
                </Col>
                <Col md={12} className="mt-4 p-0">
                  <InputField
                    backgroundColor="#EBEBFF"
                    type={!showPassword ? "password" : "text"}
                    name="password"
                    placeholder="Type your password"
                    className="w-100"
                    onChange={formik.handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <MdLockOutline size={20} className="text-light-gray" />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="center" className="ps-2  ">
                        {!showPassword ? (
                          <FiEyeOff
                            size={20}
                            className=" text-light-gray cursor-pointer"
                            onClick={() => setShowPassword(true)}
                          />
                        ) : (
                          <FiEye
                            size={20}
                            className=" text-light-gray cursor-pointer"
                            onClick={() => setShowPassword(false)}
                          />
                        )}
                      </InputAdornment>
                    }
                  />
                  {formik.touched.password && formik.errors.password && (
                    <small className="text-danger small">
                      {formik.errors.password}
                    </small>
                  )}
                </Col>
                <Col md={12} className="m-0 p-0 mt-4">
                  <Button type="submit" className="w-100 signup-button">
                    <Typography
                      fontWeight="500"
                      align="center"
                      fontSize="1rem"
                      color="#fff"
                    >
                      {user?.SIGN_IN_IS_LOADING ? <GenericLoader /> : "Sign In"}
                    </Typography>
                  </Button>

                  <div className="d-flex text-white justify-content-end align-items-end my-3 cursor-pointer ">
                    <small
                      onClick={() => navigate(ROUTER_PATH.FORGOT_PASSWORD)}
                    >
                      Forgot Password?
                    </small>
                  </div>

                  <hr className="divider mb-4" />
                  <div className="d-flex">
                    {/* <Button
                      className="bg-gray w-100 me-2 d-flex align-items-center justify-content-center"
                      type="button"
                    >
                      <LoginSocialFacebook
                        appId={API_KEYS?.FACEBOOK_APP_ID}
                        onLoginStart={onLoginStart}
                        scope="email"
                        onResolve={handleFacebookLogin}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <Typography
                          align="center"
                          fontWeight="300"
                          fontSize="1rem"
                          color="#fff"
                        >
                          <img
                            src={IMAGES.FB_ICON}
                            alt="facebook"
                            width={25}
                            className="me-2"
                          />
                          Facebook
                        </Typography>
                      </LoginSocialFacebook>
                    </Button> */}
                    <Button
                      className="signup-button w-100  d-flex align-items-center justify-content-center"
                      type="button"
                    >
                      <LoginSocialGoogle
                        client_id={API_KEYS?.GOOGLE_CLIENT_Id}
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        onLoginStart={onLoginStart}
                        onResolve={handleGoogleLogin}
                        onReject={(err) => {
                          // console.log(err);
                        }}
                      >
                        <Typography
                          align="center"
                          fontWeight="300"
                          fontSize="1rem"
                          color="#fff"
                          className="d-flex align-items-center"
                        >
                          <img
                            src={IMAGES.GOOGLE_ICON}
                            alt="google"
                            width={20}
                            className="me-2"
                          />
                          Continue with Google
                        </Typography>
                      </LoginSocialGoogle>
                    </Button>
                  </div>
                  <p className="text-white mt-4 w-100 d-flex justify-content-center">
                    Don't have an account?
                    <Link to={ROUTER_PATH.SIGN_UP} className="ms-2 auth-link">
                      Sign Up
                    </Link>
                  </p>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

import PropTypes from "prop-types";
import { Box, Typography } from "../../../Components";
import useWindowSizeHook from "../../../Components/WindowSizeHook";
import React from "react";
import { getLoggedInUser } from "../../../utils/helper";

export default function MessageTemplate({ ...props }) {
	const getLocalData = getLoggedInUser();
	const { width } = useWindowSizeHook();
	return (
		<div className=" ps-5 d-flex justify-content-end my-4 align-items-center">
			<Box
				background="#9370DB"
				borderRadius="0.75rem 0.75rem 0 0.75rem"
				width="fit-content"
				// maxWidth="100%"
				className="px-3 py-1"
			>
				<Typography
					as={width < 600 ? "label" : "p"}
					className="m-2"
					color="#fff"
					style={{ whiteSpace: "pre-wrap" }}
				>
					{props.message.split("\n").map((line, index) => (
						<React.Fragment key={index}>
							{line}
							<br />
						</React.Fragment>
					))}
				</Typography>
			</Box>

			<img
				// src={IMAGES.USER_AVATAR}
				src={`https://ui-avatars.com/api/?name=${`${
					props?.fromUser || getLocalData?.name
				}`}&background=fff&color=231671`}
				alt=""
				width="60px"
				height="60px"
				className="mx-2 rounded-circle"
			/>
		</div>
	);
}

MessageTemplate.propTypes = {
	message: PropTypes.string.isRequired,
};

import React from "react";
import MessageTemplate from "../PatientDiagnosis/components/MessageTemplate";
import ReplyTemplate from "../PatientDiagnosis/components/ReplyTemplate";
import { Box, GenericLoader, Typography } from "../../Components";

import { getLoggedInUser } from "../../utils/helper";
// import { useDispatch } from "react-redux";
// import { generateChatReportAction } from "../../Redux/actions";

const IndividualSession = ({
	messages,
	setKey,
	messagesList,
	checkChatReport,
}) => {
	const medUser = getLoggedInUser();
	// let dispatch = useDispatch();

	// function genrateUserChatReport(chatId) {
	//   let finalData = {
	//     chat_id: chatId,
	//   };
	//   dispatch(generateChatReportAction(finalData, moveToNext));
	// }

	// function moveToNext(response) {
	//   if (response?.status === 200) {
	//     setKey("report");
	//     Toaster({
	//       message: response.data.message,
	//       type: "success",
	//     });
	//   } else {
	//     Toaster({
	//       message: response.data.message,
	//       type: "error",
	//     });
	//   }
	// }

	return (
		<Box
			background="transparent"
			className="py-3 w-100 overflow-auto"
			height="calc(100vh - 300px)"
		>
			{messagesList?.GET_MESSAGE_LOADING ? (
				<div className="my-5 d-flex align-items-center justify-content-center">
					<GenericLoader />
				</div>
			) : (
				<>
					<div className="d-flex align-items-center justify-content-between">
						<Typography as="h5" color="#2E3130">
							{/* General */}
							{messagesList?.MESSAGE?.[0]?.name}
						</Typography>
						{/* {messagesList?.MESSAGE?.[0]?.reportTypeId === 301 &&
              !checkChatReport?.GENERATE_CHAT_REPORT && (
                <Button
                  height="36px"
                  type="button"
                  disabled={checkChatReport?.GENERATE_CHAT_REPORT_LOADING}
                  onClick={() => genrateUserChatReport(messages?.chatId)}
                >
                  <Typography
                    align="center"
                    fontSize="0.9rem"
                    fontWeight="500"
                    color="#fff"
                  >
                    {checkChatReport?.GENERATE_CHAT_REPORT_LOADING ? (
                      <div className="text-center">
                        <GenericLoader />
                      </div>
                    ) : (
                      " Generate Report"
                    )}
                  </Typography>
                </Button>
              )} */}
					</div>
					<Box
						width="100%"
						height="calc(100vh - 28.5rem)"
						minHeight="450px"
						background="transparent"
						className="px-sm-1 px-0  mt-4"
						// overflowY="auto"
						id="chatBox"
					>
						{messagesList?.MESSAGE?.map((item, index) => {
							return item?.toUserId === medUser?.userId ? (
								<ReplyTemplate
									reply={item}
									// reply={
									//   item?.description ? item?.description?.replace(/<END_OF_TURN>/g, "") : ""
									// }
									// loader={chat?.GET_WELCOME_MESSAGE_IS_LOADING}
									// getAudio={getAudio}
									// resetInActiveTimer={resetInActiveTimer}
									// // speakMessage={speakMessage}
									// gender={state?.gender}
								/>
							) : item?.type === "imagePrompt" ? (
								<>
									<div className="d-flex justify-content-end">
										<div className="bg-white p-3  border chat-symptoms-img">
											<img
												src={item.file}
												alt={`preview-${index}`}
												// width={300}
												height={120}
												className=""
											/>
										</div>
									</div>
									<MessageTemplate
										fromUser={item?.fromUser}
										message={item?.message}
									/>
								</>
							) : (
								<MessageTemplate message={item?.description} />
							);
						})}
					</Box>
				</>
			)}
		</Box>
	);
};

export default IndividualSession;

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useOptions } from "./useOptions";
import { Button, GenericLoader, Toaster, Typography } from "../../Components";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  createSubscriptionAction,
  updateSubscriptionAction,
} from "../../Redux/actions/subscriptionAction";
import { getLoggedInUser } from "../../utils/helper";
import { useState } from "react";
import { FaLock } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import card from "../../Assets/images/card.png";
import creditCard from "../../Assets/images/credit-card.png";
import money from "../../Assets/images/money.png";
import shopping from "../../Assets/images/shopping.png";
import cardNumber from "../../Assets/images/cvv.png";
import cvv from "../../Assets/images/cvv (1).png";

export const CheckoutForm = ({ setIsVisible, planId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const dispatch = useDispatch();
  const user = getLoggedInUser();

  const [isLoading, setIsLoading] = useState(false);

  const moveToNext = async (response) => {
    setIsLoading(false);
    if (response?.data?.succeeded) {
      Toaster({
        message: response.data.message,
        type: "success",
      });
      setIsVisible(false);
      // localStorage.setItem("med_user", JSON.stringify({ ...user, planId, user.isSubscription: false}));
      localStorage.setItem(
        "med_user",
        JSON.stringify({ ...user, planId, isSubscription: true })
      );
    } else {
      Toaster({
        message: response.data.message,
        type: "error",
      });
    }
    // console.log("before", Date.now());
    // await delay(1000);
    // console.log("after", Date.now());
    // dispatch(checkSubscriptionAction(user?.userId, checkSubscription));
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      const card = elements.getElement(CardNumberElement);
      const result = await stripe.createToken(card);

      if (result.error) {
        throw new Error(result.error.message);
      }

      const tokenId = result.token.id;

      const payload = {
        planId,
        userId: user?.userId,
        token: planId === 1 ? null : tokenId,
      };

      const subscriptionApiAction =
        user?.isSubscription === false
          ? createSubscriptionAction
          : updateSubscriptionAction;

      dispatch(subscriptionApiAction(payload, moveToNext));

      // if (user?.isSubscription === false) {
      //   dispatch(
      //     createSubscriptionAction(
      //       {
      //         planId,
      //         userId: user?.userId,
      //         token: planId === 1 ? null : tokenId,
      //       },
      //       moveToNext
      //     )
      //   );
      // } else {
      //   dispatch(
      //     updateSubscriptionAction(
      //       {
      //         planId,
      //         userId: user?.userId,
      //         token: planId === 1 ? null : tokenId,
      //       },
      //       moveToNext
      //     )
      //   );
      // }
      // await newCoachHire(tokenId);
    } catch (error) {
      Toaster({ message: error.message, type: "error" });
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 stripe">
      <div className="d-flex justify-content-end w-100">
        <IoClose
          size={20}
          onClick={() => setIsVisible(false)}
          className="cursor-pointer"
        />
      </div>
      <div className="d-flex justify-content-between align-items-center w-100 pb-3">
        <div className="d-flex  gap-2 align-items-center">
          <img src={creditCard} alt="" style={{ width: "40px" }} />
          <span className="fs-5 fw-normal">Credit Card</span>
        </div>
        <div className="d-flex gap-2">
          <img src={card} alt="" style={{ width: "35px" }} />
          <img src={money} alt="" style={{ width: "35px" }} />
          <img src={shopping} alt="" style={{ width: "35px" }} />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            Card number
            <div className="position-relative">
              <CardNumberElement options={options} />
              <img
                className="position-absolute"
                src={cardNumber}
                alt=""
                style={{ width: "35px", right: "2%", top: "10%" }}
              />
            </div>
          </Col>

          <Col lg={6}>
            Expiry date
            <CardExpiryElement options={options} />
          </Col>
          <Col lg={6}>
            CVC/CVV
            <div className="position-relative">
              <CardCvcElement options={options} />
              <img
                className="position-absolute"
                src={cvv}
                alt=""
                style={{ width: "35px", right: "2%", top: "10%" }}
              />
            </div>
          </Col>
          <Col lg={12}>
            <Button disable={isLoading} type="submit" className="w-100 mt-2">
              <Typography
                align="center"
                fontSize="1rem"
                fontWeight="500"
                color="#fff"
                className="d-flex justify-content-center align-items-center gap-2"
              >
                {isLoading ? (
                  <GenericLoader />
                ) : (
                  <>
                    <FaLock /> Add Card{" "}
                  </>
                )}
              </Typography>
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsEye, BsFillMicMuteFill, BsFillStopFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Loader from "react-dots-loader";
import { FaCircleArrowUp } from "react-icons/fa6";

import {
	Box,
	Button,
	ConfirmationModal,
	GenericInput,
	GenericLoader,
	GenericModal,
	Toaster,
	Typography,
} from "../../Components";
import {
	endSessionAction,
	generateChatNameAction,
	getChatByUsers,
	getDoctorProfile,
	getPromptResponse,
	getUserChatHistory,
	getWelcomeMessage,
	saveChatAction,
	saveMessage,
	saveUserChat,
} from "../../Redux/actions";
import { BASE_URL, ROUTER_PATH } from "../../config";
import ReplyTemplate from "./components/ReplyTemplate";
import MessageTemplate from "./components/MessageTemplate";
import { Col, Form, Row } from "react-bootstrap";
import { IMAGES } from "../../Assets";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
// import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import { getAudioFromTextApi } from "../../Redux/api/chat";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { getLoggedInUser } from "../../utils/helper";
import UpgradeModal from "./components/UpgradeModal";
import { FaPowerOff } from "react-icons/fa";

export default function PatientDiagnosis() {
	const [file, setFile] = useState();
	const [previewImg, setPreviewImg] = useState(null);
	const [previewVisible, setPreviewVisible] = useState(false);
	// const [previewImage, setPreviewImage] = useState("");
	const [showEye, setShowEye] = useState(false);

	const inActiveTimer = useRef(null);
	const inputRef = useRef(null);
	const [inActiveModal, setInActiveModal] = useState(false);
	const { state } = useLocation();
	const [textareaValue, setTextareaValue] = useState("");
	const [chatArray, setChatArray] = useState([]);
	const [userPrompt, setUserPrompt] = useState([]);
	const [confirmationModalShow, setConfirmationModalShow] = useState(false);
	const [chatName, setChatName] = useState();
	const [audioBlob, setAudioBlob] = useState(null);
	const [playPause] = useState(null);
	const [chatId, setChatId] = useState(null);
	const [isPermissionGranted, setIsPermissionGranted] = useState(false);
	const [, setSpeechToTextResponse] = useState(null);
	const playerRef = useRef();
	const USER_ID = localStorage.getItem("userId");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const medUser = getLoggedInUser();
	const chat = useSelector((state) => state.chat);
	console.log(chat, "CHAT");

	const [totalMessageCount, setTotalMessageCount] = useState(null);

	function startInActiveTimer() {
		inActiveTimer.current = setTimeout(() => {
			clearTimeout(inActiveTimer.current);
			setInActiveModal(true);
			// }, 10000);
		}, 600000);
	}

	console.log("genderName", chatArray, userPrompt, totalMessageCount);

	function resetInActiveTimer() {
		clearTimeout(inActiveTimer.current);
		startInActiveTimer();
	}

	const handleTextareaChange = (event) => {
		setTextareaValue(event.target.value);
		resetInActiveTimer();
	};

	const updateUserPrompt = () => {
		const existingMessageIds = new Set(
			userPrompt.map((message) => message.messageId)
		);

		const newMessages = chat.CHAT[0].messages.filter(
			(message) =>
				message.fromUser !== "Robot Doctor" &&
				!existingMessageIds.has(message.messageId)
		);

		setUserPrompt([...userPrompt, ...newMessages]);
	};

	useEffect(() => {
		if (!chat?.DOCTOR_PROFILE?.data[0]?.doctorId)
			dispatch(getDoctorProfile({ isSystemDoctor: true }));
	}, [chat?.DOCTOR_PROFILE?.data, dispatch]);

	useEffect(() => {
		if (medUser?.userId && chat?.DOCTOR_PROFILE?.data[0]?.doctorId) {
			dispatch(
				getChatByUsers({
					pageNo: 1,
					size: 20,
					search: null,
					isPagination: true,
					sortBy: null,
					sortDirection: null,
					fromUserId: medUser?.userId,
					toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
					isSessionEnd: false,
					isTitleMessage: false,
				})
			);
		}
	}, [chat?.DOCTOR_PROFILE?.data, medUser?.userId]);

	const saveReply = (response) => {
		setSpeechToTextResponse(null);
		dispatch(
			saveMessage({
				fromUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
				toUserId: medUser?.userId,
				chatId: response?.chatId,
				description: response?.reply,
				isFirstMessage: false,
				// isFirstMessage: chat?.MESSAGE?.length >= 0 ? false : true,
			})
		);
	};

	const saveChatMoveToNext = (response, reply) => {
		setChatId(response?.data?.data);
		dispatch(
			saveMessage(
				{
					fromUserId: medUser?.userId,
					toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
					chatId: response?.data?.data,
					description: textareaValue,
					isFirstMessage: userPrompt?.length === 0 ? true : false, //    chat?.MESSAGE?.length > 0 ? false : true,
				},
				saveReply,
				reply
			)
		);
		setTextareaValue("");
	};

	const chatMoveToNext = (reply, userText) => {
		// console.log(
		//   "chat?.CHAT?.length",
		//   chat?.CHAT?.length,
		//   chat?.DOCTOR_PROFILE,
		//   chat?.DOCTOR_PROFILE?.data?.[0]?.doctorId
		// );
		// if (!chat?.CHAT?.[0]?.chatId) {
		//   dispatch(
		//     getChatByUsers({
		//       pageNo: 1,
		//       size: 20,
		//       search: null,
		//       isPagination: true,
		//       sortBy: null,
		//       sortDirection: null,
		//       fromUserId: medUser?.userId,
		//       toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
		//       isSessionEnd: false,
		//     })
		//   );
		// }
		if (chat?.CHAT?.length === 0 && !chat?.MESSAGE?.[0]?.chatId) {
			dispatch(
				saveChatAction(
					{
						name: "",
						fromUserId: medUser?.userId,
						toUserId: chat?.DOCTOR_PROFILE?.data?.[0]?.doctorId,
						roleId: state?.gender === "male" ? 101 : 102,
					},
					saveChatMoveToNext,
					reply
				)
			);
		} else {
			dispatch(
				saveMessage(
					{
						fromUserId: medUser?.userId,
						toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
						chatId:
							chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || null,
						description: textareaValue !== "" ? textareaValue : userText,
						isFirstMessage: userPrompt?.length === 0 ? true : false, // chat?.MESSAGE?.length > 0 ? false : true,
						// isFirstMessage:
						//   !chat?.CHAT?.[0] || !chat?.MESSAGE?.[0] ? true : false,
					},
					saveReply,
					reply
				)
			);
		}
	};
	// console.log("chat?.CHAT", chat);

	const sendPrompt = (messageValue) => {
		resetInActiveTimer();
		//
		const OptionalPayload = {
			name: medUser?.name,
			age: medUser?.age,
			gender: medUser?.gender,
			occupation: medUser?.occupation,
			firstMessage: userPrompt?.length === 0 ? true : false,
		};

		const prompt = {
			id: Date.now() + Math.random(),
			type: `${file ? "imagePrompt" : "prompt"}`,
			message: messageValue,
			file: previewImg,
		};
		setChatArray([...chatArray, prompt]);
		setUserPrompt([...userPrompt, prompt]);
		setTotalMessageCount((prev) => prev + 1);
		setPreviewImg(null);
		setTextareaValue("");
		dispatch(
			getPromptResponse(
				messageValue,
				OptionalPayload,
				chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || null,
				state?.gender === "male" ? 0 : 1,
				file,
				chatMoveToNext
			)
		);
	};

	const handleButtonClick = (e) => {
		e.preventDefault();
		const simulatedEvent = { key: "Enter", preventDefault: e.preventDefault };
		handleKeyPress(simulatedEvent);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			if (e.shiftKey) {
				// Prevent the default action of the Enter key
				e.preventDefault();
				// Add a new line to the textarea value
				setTextareaValue((prevValue) => prevValue + "\n");
			} else {
				// Prevent the default action of the Enter key
				e.preventDefault();

				// Trim the textarea value to remove leading and trailing whitespace
				const trimmedValue = textareaValue.trim();

				// Check if the trimmed value is not empty
				if (trimmedValue !== "") {
					if (
						medUser?.planId === 1 &&
						(userPrompt?.length >= 10 || totalMessageCount >= 10)
					) {
						setIsVisible(true);
					} else {
						sendPrompt(trimmedValue);
					}
					setTextareaValue("");
				}
			}
		}
	};

	const handleSaveChat = () => {
		dispatch(saveUserChat(chatName, USER_ID, Toaster, moveToNext));
	};

	const moveToNext = () => {
		setConfirmationModalShow(false);
		dispatch(getUserChatHistory(USER_ID));
		setChatArray([]);
		setUserPrompt([]);
	};

	const scrollFunction = () => {
		const scroller = document.querySelector("#chatBox");
		scroller.scroll(0, 10000);
	};

	useEffect(() => {
		if (state?.gender === undefined) {
			navigate(ROUTER_PATH.HOME_PAGE);
		}
		scrollFunction();
	}, [chatArray]);

	useEffect(() => {
		// if (state?.gender !== null && unityLoaded) {
		if (state?.gender !== null) {
			dispatch(getWelcomeMessage());
			// dispatch(getWelcomeMessage({ id: state?.gender === "male" ? 0 : 1 }));
		}
		// }, [dispatch, state, unityLoaded]);
	}, [dispatch, state]);

	const getAudio = async (text, id) => {
		// dispatch(getAudioFromText());
		let sendData = {
			text: text,
			id: id,
		};
		const response = await getAudioFromTextApi(sendData);
		const audioDataUrl = `data:audio/mp3;base64,${response.data}`;
		playPause ? setVideoPlaying(false) : setVideoPlaying(true);
		resetInActiveTimer();
		setAudioBlob(audioDataUrl);
	};

	// useEffect(() => {
	//   if (audioBlob) {
	//     setVideoPlaying(true);
	//   }

	//   return () => {
	//     setVideoPlaying(false);
	//   };
	// }, [audioBlob]);

	useEffect(() => {
		// if (unityLoaded) {
		let reply = {
			id: Date.now() + Math.random(),
			type: "reply",
			toUserId: medUser?.userId,
			description: chat?.WELCOME_MESSAGE,
		};
		if (chat?.CHAT?.length < 1) {
			setChatArray([reply]);
		} else {
			setChatArray([reply, ...chat?.CHAT[0]?.messages]);
			updateUserPrompt();
		}
		startInActiveTimer();
		setTotalMessageCount(chat?.TOTAL_MESSAGES);
		// }
		// }, [chat?.WELCOME_MESSAGE, unityLoaded]);
	}, [chat?.WELCOME_MESSAGE, chat?.CHAT]);

	useEffect(() => {
		if (chat?.PROMPT_RESPONSE?.data) {
			let reply = {
				id: Date.now() + Math.random(),
				type: "reply",
				toUserId: medUser?.userId,
				description: chat?.PROMPT_RESPONSE?.data,
			};
			setChatArray([...chatArray, reply]);
			// resetInActiveTimer();
			setFile(null);
		}
		// eslint-disable-next-line
	}, [chat?.PROMPT_RESPONSE?.data]);

	const [spokenMessages, setSpokenMessages] = useState([]);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const [startListening, setStartListening] = useState(false);

	const handleReplyMessage = (item) => {
		if (item.type === "reply" && !spokenMessages.includes(item.id)) {
			// Update gender value according to data
			if (state?.gender !== null && item.description !== null) {
				// speakMessage(item.reply, state?.gender);
				// commented by Mahid
				getAudio(item.description, state?.gender === "female" ? 1 : 0);
				resetInActiveTimer();
			}
			setSpokenMessages((prevMessages) => [...prevMessages, item.id]);
		}
	};

	useEffect(() => {
		// Speak "reply" messages when the component
		chatArray?.forEach(handleReplyMessage);
	}, [chatArray, audioBlob]);

	let mediaRecorder = null;
	let recordingStart = false;
	let audioStream = null;
	const timerRef = useRef(null);
	const MIN_DECIBELS = -40;

	const stopRecordingVoice = () => {
		mediaRecorder?.stop();
		audioStream?.getTracks()?.forEach((track) => track?.stop());
		recordingStart = false;
		setStartListening(false);
	};

	const startTimeCount = () => {
		timerRef.current = setTimeout(() => {
			// console.log("no voice");
			clearTimeout(timerRef.current);
			stopRecordingVoice();
			// console.log("recording stop");
		}, 5000);
	};

	function handleResetTimer() {
		// console.log("reseting");
		clearTimeout(timerRef.current);
		startTimeCount();
	}

	const startVoiceRecording = async () => {
		resetInActiveTimer();
		audioStream = await navigator.mediaDevices.getUserMedia({
			audio: true,
		});
		mediaRecorder = new MediaRecorder(audioStream);
		mediaRecorder.start();

		recordingStart = true;
		// console.log("recording start");
		startTimeCount();
		const audioChunks = [];
		mediaRecorder.addEventListener("dataavailable", (event) => {
			audioChunks.push(event.data);

			resetInActiveTimer();
		});

		const audioContext = new AudioContext();
		const audioStreamSource = audioContext.createMediaStreamSource(audioStream);
		const analyser = audioContext.createAnalyser();
		analyser.minDecibels = MIN_DECIBELS;
		audioStreamSource.connect(analyser);

		const bufferLength = analyser.frequencyBinCount;
		const domainData = new Uint8Array(bufferLength);

		let soundDetected = false;

		const detectSound = () => {
			if (!recordingStart) {
				// console.log("not recording");
				return;
			}

			analyser.getByteFrequencyData(domainData);

			for (let i = 0; i < bufferLength; i++) {
				// const value = domainData[i];

				if (domainData[i] > 0) {
					// console.log("sound detected");
					soundDetected = true;
					handleResetTimer();
				}
			}

			window.requestAnimationFrame(detectSound);
		};

		window.requestAnimationFrame(detectSound);

		mediaRecorder.addEventListener("stop", async () => {
			if (soundDetected) {
				const audioBlob = new Blob(audioChunks, {
					type: "audio/wav",
				});
				sendAudioToAPI(audioBlob);
			}
			// console.log("soundDetected", soundDetected);
			resetInActiveTimer();
		});
	};

	const checkAndRequestMicrophonePermission = async (startListen = false) => {
		resetInActiveTimer();
		try {
			// const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
			setIsPermissionGranted(true);

			// audioStream?.getTracks().forEach((track) => track.stop());
			if (startListen) {
				setStartListening(true);
			}
		} catch (error) {
			setIsPermissionGranted(false);
		}
	};

	useEffect(() => {
		// Add the window event listener for beforeunload
		const handleBeforeUnload = () => {
			if (speechSynthesis.speaking) {
				speechSynthesis.cancel();
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);
		// checkAndRequestMicrophonePermission();
		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);

			// Stop speech synthesis when the component unmounts
			if (speechSynthesis.speaking) {
				speechSynthesis.cancel();
			}
		};
	}, []);

	// function isPlaying(audelem) {
	//   return !audelem.paused;
	// }

	const sendAudioToAPI = async (audioData) => {
		try {
			const formData = new FormData();
			formData.append("audio_file", audioData, "recorded-audio.wav");
			const response = await axios.post(
				BASE_URL + "/speech_to_text",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			await setSpeechToTextResponse(response?.data?.response);
			sendPrompt(response.data.response);

			// setSentVoice(false);
			// setAudioBlob(null);
		} catch (error) {
			console.error("Error sending audio to API:", error);
		}
	};

	// console.log("speech to text", speechToTextResponse);

	// useEffect(() => {
	//   if (audioBlob && sentVoice) {
	//     sendAudioToAPI();
	//   }
	// }, [audioBlob, sentVoice]);

	const startVoiceCommand = () => {
		resetInActiveTimer();
		if (!videoPlaying) {
			if (isPermissionGranted) {
				setStartListening(true);
			} else {
				// Request microphone permission
				checkAndRequestMicrophonePermission(true);
			}
		}
	};

	useEffect(() => {
		if (startListening) {
			var resetTimer = setTimeout(() => {
				resetInActiveTimer();
			}, 1000);
			startVoiceRecording();
		}
		return () => {
			clearTimeout(resetTimer);
		};
	}, [startListening]);

	const { width } = useWindowSizeHook();

	const audioRef = useRef(null);

	const handlePlayPause = () => {
		if (audioRef?.current?.paused) {
			// audioRef.current.play();
		} else {
			// audioRef.current.pause();
			resetInActiveTimer();
		}
	};

	useEffect(() => {
		let handlePlayPauseTimer = setTimeout(() => {
			handlePlayPause();
		}, 10000);
		return () => {
			clearTimeout(handlePlayPauseTimer);
		};
	}, []);

	const handlePause = () => {
		// Check if the player reference exists
		if (playerRef?.current) {
			// Set the current time of the video to 0 seconds
			playerRef?.current?.seekTo(0);
		}
	};

	const handleImageUpload = (e) => {
		const selectedFile = e.target.files[0];
		setFile(selectedFile);

		if (selectedFile) {
			const imageUrl = URL.createObjectURL(selectedFile);
			setPreviewImg(imageUrl);
		}
	};

	const handlePreview = (url) => {
		setPreviewVisible(true);
	};

	const handleImageRemove = () => {
		setPreviewImg(null);
	};

	const sessionEndSuccess = () => {
		let finalData = {
			chat_id: chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId,
		};
		dispatch(generateChatNameAction(finalData));
		navigate(ROUTER_PATH.THANK_YOU);
	};

	const handleEndSession = () => {
		if (chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId) {
			dispatch(
				endSessionAction(
					{
						chatId:
							chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId,
					},
					sessionEndSuccess
				)
			);
		} else {
			navigate(ROUTER_PATH.HOME_PAGE);
		}
	};

	const [isVisible, setIsVisible] = useState(false);
	const [isMuted, setIsMuted] = useState(false);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.muted = isMuted;
		}
	}, [isMuted, audioBlob]);

	const toggleMute = () => {
		setIsMuted((prevState) => !prevState);
	};

	return (
		<div className="d-lg-flex" id="app-content">
			<Box
				width={
					width < 992
						? "25%"
						: width < 1300
						? "20rem"
						: width < 1500
						? "25rem"
						: "30rem"
				}
				height="100%"
				borderRadius="1.375rem"
				background={width < 992 ? "" : "#fff"}
				shadow={width < 992 ? "" : "0px 0px 40px 20px rgba(0, 0, 0, 0.09)"}
				backdropFilter="blur(15px)"
				className={` ${
					width < 992
						? "float-end  mt-3"
						: "mb-5 mb-lg-0 chat-history-box me-3 py-3 chat-box-a"
				} `}
			>
				<div>
					<Typography
						className={`${width < 992 ? "d-none" : ""}`}
						as="h6"
						align="center"
						color="rgb(147, 112, 219)"
					>
						{state?.gender === "male" ? "Dr. William" : "Dr. Elizabeth"}
					</Typography>
				</div>
				<div
					className={` ${
						width < 992
							? "m-0"
							: "video-container text-center w-100 mb-auto mt-2 position-relative"
					}  `}
				>
					<div className="d-none">
						{audioBlob && (
							<audio
								autoPlay
								ref={audioRef}
								onEnded={() => {
									setVideoPlaying(false);
									setAudioBlob(null);
									resetInActiveTimer();
								}}
								src={audioBlob}
							>
								Your browser does not support the audio tag.
							</audio>
						)}
					</div>

					<ReactPlayer
						url={
							state?.gender === "male"
								? IMAGES.MALE_DOCTOR
								: IMAGES.FEMALE_DOCTOR
						}
						ref={videoPlaying ? playerRef : null}
						muted
						playing={videoPlaying}
						loop={videoPlaying}
						width={`${width < 992 ? "90%" : "100%"}`}
						height={"100%"}
						onPause={handlePause}
					/>
					{/* {state?.gender === "male" ? (
            <ReactPlayer
              url={IMAGES.MALE_DOCTOR}
              ref={playerRef}
              muted
              playing={videoPlaying}
              loop={videoPlaying}
              width={`${width < 992 ? "90%" : "100%"}`}
              height={"100%"}
              onPause={handlePause}
            />
          ) : (
            state?.gender === "female" && (
              <ReactPlayer
                url={IMAGES.FEMALE_DOCTOR}
                ref={playerRef}
                muted
                playing={videoPlaying}
                loop={videoPlaying}
                width={`${width < 992 ? "90%" : "100%"}`}
                height={"100%"}
                onPause={handlePause}
              />
            )
          )} */}
				</div>
				<div className={`${width < 992 ? "d-none" : "w-100"}`}>
					<Row className="justify-content-start m-0 px-2 mb-3">
						<Col sm={12}>
							<Row>
								{state?.vitals?.map((item) => (
									<Col sm={6}>
										<Typography as="label" color="#636967" className="mt-2">
											{item.title}
										</Typography>
										<Typography as="p" color="#4A4F4D">
											{item.desc}
										</Typography>
									</Col>
								))}
							</Row>
						</Col>
					</Row>
				</div>
				<div
					className={`${
						width < 992
							? "d-none"
							: "px-3 my-2 w-100 d-flex align-items-center justify-content-center flex-column gap-2"
					}`}
				>
					<input
						ref={inputRef}
						onChange={handleImageUpload}
						className="d-none"
						type="file"
					/>
				</div>
				<div className="px-3 w-100 d-flex align-items-center justify-content-center  ">
					<Button
						height="3rem"
						minWidth={width < 1300 ? "8rem" : "10rem"}
						// disabled={medUser?.planId === 1}
						className={`${width < 992 ? "d-none" : ""} mx-auto`}
						onClick={() => {
							handleEndSession();
						}}
					>
						<Typography
							color="#fff"
							fontSize="1.5rem"
							fontWeight="500"
							className="text-center w-100"
						>
							{chat?.END_SESSION_LOADING ? <GenericLoader /> : "End Session"}
						</Typography>
					</Button>
					{/* <Button
            height="3rem"
            minWidth={width < 1300 ? "6rem" : "10rem"}
            // disabled={medUser?.planId === 1}
            className={`${width < 992 ? "d-none" : ""}`}
            backgroundColor="rgb(83 147 44)"
            onClick={() => {
              setPlayPause(!playPause);
              setAudioBlob(null);
              setVideoPlaying(false);
              handlePause();
            }}
          >
            <Typography
              color="#fff"
              fontSize="1.5rem"
              fontWeight="500"
              className="text-center w-100"
            >
              {playPause ? "Unmute" : "Mute"}
            </Typography>
          </Button> */}
					<div
						className={`${width < 992 ? "d-none" : ""}`}
						onClick={() => {
							toggleMute();
							// setPlayPause(!playPause);
							// setAudioBlob(null);
							// setVideoPlaying(false);
							// handlePause();
						}}
					>
						{isMuted ? (
							<VolumeOffIcon
								className="fs-1 "
								style={{ color: "rgb(83 147 44)" }}
							/>
						) : (
							<VolumeUpIcon
								className="fs-1 "
								style={{ color: "rgb(83 147 44)" }}
							/>
						)}
					</div>
				</div>
			</Box>
			<Box
				width="100%"
				height="100%"
				maxHeight="35rem"
				maxWidth="202rem"
				borderRadius="1.375rem"
				background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
				shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
				backdropFilter="blur(15px)"
				className="pt-5 mb-auto mb-5 w-100 chat-box-b"
			>
				<div>
					<Typography
						as={width < 600 ? "h6" : "h5"}
						align="center"
						color="rgb(147, 112, 219)"
					>
						Patient Diagnosis
					</Typography>
					<div
						className={
							width < 992 && width > 800
								? "d-flex justify-content-end align-items-end mt-3 "
								: width < 992
								? "d-flex justify-content-end align-items-end "
								: "d-none"
						}
					>
						<div className=""></div>
						{width < 650 ? (
							<div className="d-flex justify-content-end align-items-center gap-2">
								<FaPowerOff
									onClick={() => handleEndSession()}
									size={25}
									color="#ff0000ba"
								/>
								<div onClick={() => toggleMute()}>
									{isMuted ? (
										<VolumeOffIcon
											className="fs-1 me-3"
											style={{ color: "rgb(83 147 44)" }}
										/>
									) : (
										<VolumeUpIcon
											className="fs-1 me-3"
											style={{ color: "rgb(83 147 44)" }}
										/>
									)}
								</div>
							</div>
						) : (
							<>
								<Button
									onClick={() => handleEndSession()}
									className={` text-white fw-normal me-3 ${
										width > 650 && width < 700
											? "mt-2"
											: width > 700 && width < 750
											? "mt-3"
											: width > 750 && width < 770
											? "mt-4"
											: ""
									}`}
									borderRadius="8px"
									height="2.7rem"
									minWidth="7rem"
								>
									End Session
								</Button>
								<div
									className={` text-white fw-normal me-3 ${
										width > 650 && width < 700
											? "mt-2"
											: width > 700 && width < 750
											? "mt-3"
											: width > 750 && width < 770
											? "mt-4"
											: ""
									}`}
									onClick={() => {
										toggleMute();
										// setPlayPause(!playPause);
										// setAudioBlob(null);
										// setVideoPlaying(false);
										// handlePause();
									}}
								>
									{isMuted ? (
										<VolumeOffIcon
											className="fs-1 "
											style={{ color: "rgb(83 147 44)" }}
										/>
									) : (
										<VolumeUpIcon
											className="fs-1 "
											style={{ color: "rgb(83 147 44)" }}
										/>
									)}
								</div>
								{/* <Button
                  onClick={() => {
                    setPlayPause(!playPause);
                    setAudioBlob(null);
                    setVideoPlaying(false);
                  }}
                  className={` text-white fw-normal me-3 ${
                    width > 650 && width < 700
                      ? "mt-2"
                      : width > 700 && width < 750
                      ? "mt-3"
                      : width > 750 && width < 770
                      ? "mt-4"
                      : ""
                  }`}
                  borderRadius="8px"
                  height="2.7rem"
                  minWidth="6rem"
                  backgroundColor="rgb(83 147 44)"
                >
                  {playPause ? "Play" : "Pause"}
                </Button> */}
							</>
						)}
					</div>
				</div>
				<Box
					width="100%"
					height="calc(100vh - 28.5rem)"
					minHeight="450px"
					background="transparent"
					className="px-sm-1 px-lg-5  mt-4"
					overflowY="auto"
					id="chatBox"
				>
					{chatArray?.map((item, index) => {
						return item?.toUserId === medUser?.userId ? (
							<ReplyTemplate
								reply={
									item?.reply
										? item?.reply
										: // ? item?.reply?.replace(/<END_OF_TURN>/g, "")
										  // : item?.description?.replace(/<END_OF_TURN>/g, "")
										  item?.description
								}
								loader={chat?.GET_WELCOME_MESSAGE_IS_LOADING}
								getAudio={getAudio}
								resetInActiveTimer={resetInActiveTimer}
								// speakMessage={speakMessage}
								gender={state?.gender}
								videoPlaying={videoPlaying}
								setVideoPlaying={setVideoPlaying}
								setAudioBlob={setAudioBlob}
								setIsMuted={setIsMuted}
							/>
						) : (
							<MessageTemplate
								fromUser={item?.fromUser}
								message={item?.message || item?.description}
							/>
						);
					})}
					{(chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
						chat?.GET_PROMPT_RESPONSE_IS_LOADING) && (
						<Loader size={10} className="m-3" />
					)}
				</Box>

				<Box
					width="100%"
					background="var(--secondary-150, #F4FBEA)"
					borderRadius="0.75rem 0.75rem 0 0"
					className="d-flex flex-wrap justify-content-between py-2 px-sm-1 px-lg-3 "
				>
					<div className="d-flex align-items-center justify-content-center bg-red-700">
						{startListening && chat?.GET_PROMPT_RESPONSE_IS_LOADING !== true ? (
							<Box
								width="2.2rem"
								height="2.2rem"
								// isTooltip={"Press to stop"}
								background="#ED6A58"
								className="rounded-circle d-flex justify-content-center align-items-center mx-3 cursor-pointer"
								onClick={() => {
									setStartListening(false);
									// setSentVoice(true);
									resetInActiveTimer();
									stopRecordingVoice();
								}}
							>
								<BsFillStopFill color="white" size={width > 1360 ? 24 : 15} />
							</Box>
						) : (
							<Box
								width="2.2rem"
								height="2.2rem"
								// isTooltip={videoPlaying ? width < 500 ?? false : true}
								background={
									videoPlaying || chat?.GET_PROMPT_RESPONSE_IS_LOADING === true
										? "#898989"
										: "#53972C"
								}
								className={`rounded-circle d-flex justify-content-center align-items-center mx-3 mt-1 ${
									videoPlaying || chat?.GET_PROMPT_RESPONSE_IS_LOADING === true
										? "cursor-not-allowed"
										: "cursor-pointer"
								}`}
								onClick={
									chat?.GET_PROMPT_RESPONSE_IS_LOADING === true
										? null
										: medUser?.planId === 1 &&
										  (userPrompt?.length >= 10 || totalMessageCount >= 10)
										? () => setIsVisible(true)
										: startVoiceCommand
								}
							>
								<BsFillMicMuteFill
									color="#ffffff"
									size={width > 1360 ? 20 : 15}
								/>
							</Box>
						)}
						{startListening &&
							chat?.GET_PROMPT_RESPONSE_IS_LOADING !== true && (
								<Loader size={10} />
							)}
						{!startListening && (
							<b className="me-2">
								<small style={{ fontSize: width < 600 ? "11px" : "13px" }}>
									If you prefer to speak to the doctor, rather than type, please
									press the mic button to speak.
								</small>
							</b>
						)}
					</div>
				</Box>
				<div className="scroll-hide">
					<div className={`upload-img ${previewImg ? "pt-3" : ""}`}>
						{previewImg && (
							<div
								className="cursor-pointer transform border transition duration-500  symptoms-img"
								onMouseEnter={() => {
									setShowEye(true);
								}}
								onMouseLeave={() => setShowEye(false)}
							>
								<img
									src={previewImg}
									alt={`previewImag`}
									height={60}
									width={100}
									className="p-2"
									onClick={() => handlePreview()}
								/>

								<RxCross2
									onClick={() => handleImageRemove()}
									fontSize={18}
									className="remove-icon"
								/>
								{showEye && (
									<div
										className="eye-icon-overlay"
										onClick={() => handlePreview(file)}
									>
										<BsEye className=" cursor-pointer me-1" fontSize={18} />{" "}
									</div>
								)}
							</div>
						)}
						{previewImg && (
							<div className="border-bottom border-light-subtle mx-4 mt-3 " />
						)}

						<div className="input-field">
							<div
								style={{ display: "flex", alignItems: "center", width: "100%" }}
							>
								<GenericInput
									placeholder="Enter your message"
									fontSize={width < 600 ? "14px" : "1rem"}
									width="100%"
									minHeight="100%"
									value={textareaValue}
									onChange={(e) => handleTextareaChange(e)}
									onKeyPress={(e) => handleKeyPress(e)}
									as="textarea"
									border="none"
									borderRadius="0 0 1.375rem 2rem"
									maxLength={1000}
									disabled={chat?.GET_PROMPT_RESPONSE_IS_LOADING}
								/>
								<button
									onClick={handleButtonClick}
									disabled={!textareaValue.trim()}
									style={{
										background: "none",
										border: "none",
										cursor: textareaValue.trim() ? "pointer" : "not-allowed",
										padding: "0 10px",
										opacity: textareaValue.trim() ? 1 : 0.5,
									}}
								>
									<FaCircleArrowUp size={30} color="#000" />
								</button>
							</div>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<span style={{ fontSize: "12px", color: "#757575" }}>
									{textareaValue.length}/1000 characters
								</span>
							</div>
						</div>
					</div>
				</div>
			</Box>
			{/*  */}
			{confirmationModalShow && (
				<ConfirmationModal
					show={confirmationModalShow}
					onHide={() => setConfirmationModalShow(false)}
					size="md"
					title={<Typography as="h6">Save Chat in History</Typography>}
					successAction={handleSaveChat}
					loader={chat?.SAVE_CHAT_HISTORY_IS_LOADING}
				>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>
							<Typography as="p" size="1.5rem">
								Chat Name
							</Typography>
						</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter Name of Chat"
							onChange={(e) => {
								setChatName(e.target.value);
							}}
							autoFocus
						/>
					</Form.Group>
				</ConfirmationModal>
			)}

			<ConfirmationModal
				show={inActiveModal}
				onHide={() => setInActiveModal(false)}
				size="md"
				hideFooter={true}
				className="d-flex justify-content-center align-items-center custom-modal-style"
			>
				<div className="text-center w-100 px-2 px-md-5">
					<Typography
						align="center"
						fontSize={width < 500 ? "1rem" : "1.2rem"}
						fontWeight="500"
						className="p-3"
					>
						Hey, are you still with us?
					</Typography>
					<div className="d-flex gap-3  justify-content-center align-items-center">
						<Button
							className="bg-secondary"
							minWidth={width < 500 ? "6rem" : "8rem"}
							height={width < 500 ? "2.5rem" : "3rem"}
							onClick={() => {
								handleEndSession();
							}}
						>
							<Typography
								align="center"
								fontSize="1rem"
								fontWeight="500"
								color="#fff"
							>
								No
							</Typography>
						</Button>
						<Button
							minWidth={width < 500 ? "6rem" : "8rem"}
							height={width < 500 ? "2.5rem" : "3rem"}
							onClick={() => {
								setInActiveModal(false);
								resetInActiveTimer();
							}}
							className="px-4 py-2 text-white"
							variant="success"
						>
							<Typography
								color="#fff"
								fontSize="1.3rem"
								fontWeight="400"
								className="text-center w-100"
							>
								Yes
							</Typography>
						</Button>
					</div>
				</div>
			</ConfirmationModal>
			<GenericModal
				show={previewVisible}
				onHide={() => setPreviewVisible(false)}
			>
				<img alt="Preview" style={{ width: "100%" }} src={previewImg} />
			</GenericModal>
			<UpgradeModal isVisible={isVisible} setIsVisible={setIsVisible} />
		</div>
	);
}

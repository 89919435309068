import { getMessageApi, saveMessageApi } from "../api/message";

export function saveMessage(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_MESSAGE_REQUEST",
    });
    saveMessageApi(payload).then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: "SAVE_MESSAGE_SUCCESS",
            payload: response.data,
          });
          // if (Toaster)
          //   Toaster({
          //     message: `Chat save Successfully`,
          //     type: "success",
          //   });
          if (moveToNext) moveToNext();
        }
      },
      (error) => {
        dispatch({
          type: "SAVE_MESSAGE_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function getMessage(chatId) {
  return (dispatch) => {
    dispatch({
      type: "GET_MESSAGE_REQUEST",
    });
    getMessageApi(chatId).then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: "GET_MESSAGE_SUCCESS",
            payload: response?.data?.data,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_MESSAGE_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATH } from "../../config";
import { IMAGES } from "../../Assets";
import { Button } from "../../Components";
import { IconButton, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useWindowSizeHook from "../../Components/WindowSizeHook";

const drawerWidth = 300;

const menuItems = [
  {
    text: "Dashboard",
    icon: <GridViewRoundedIcon />,
    path: ROUTER_PATH.Dashboard,
  },
  // {
  //   text: "Find a Doctor",
  //   icon: <FaUserDoctor />,
  //   path: ROUTER_PATH.Dashboard_Find_Doctor,
  // },
  // {
  //   text: "My Appointments",
  //   icon: <CalendarMonthIcon />,
  //   path: ROUTER_PATH.Dashboard_My_Appointments,
  // },
];

export default function Dashboard() {
  const { width: useWidth } = useWindowSizeHook();
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(
    useWidth < 800 ? false : true
  );
  const handleLogout = () => {
    localStorage.removeItem("med_user");
    localStorage.removeItem("medistreamline");
    navigate(ROUTER_PATH.SIGN_IN);
    window.location.reload(); // Refresh the page
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={1}
        position="fixed"
        sx={{
          width: drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
          ml: drawerOpen ? `${drawerWidth}px` : 0,
          color: "black",
          background: "white",
          transition: "width 0.3s ease-in-out, margin-left 0.3s ease-in-out",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={useWidth > 800 ? toggleDrawer : null} // Add this line
            sx={{ mr: 2 }}
          >
            {useWidth > 800 ? <MenuIcon /> : null}
          </IconButton>
          <Typography variant="h5" noWrap component="div" className="me-auto">
            Dashboard
          </Typography>
          <Button
            type="submit"
            onClick={handleLogout}
            minWidth="6rem"
            height="2.5rem"
          >
            <Typography
              align="center"
              fontWeight="500"
              fontSize="1rem"
              color="#fff"
            >
              Logout
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? drawerWidth : 0,
            boxSizing: "border-box",
            background: "#9370DB",
            color: "white",
          },
        }}
        variant="persistent" // Change to persistent
        anchor="left"
        open={drawerOpen} // Add this line
      >
        <Toolbar>
          <Link to={ROUTER_PATH.HOME_PAGE}>
            <img
              src={IMAGES.MEDISTREAMLINE_LOGO}
              width="250px"
              className="d-inline-block align-center mt-4"
              alt="React Bootstrap logo"
            />
          </Link>
        </Toolbar>
        <Divider />
        <Toolbar></Toolbar>
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text}>
              <ListItemButton
                component={Link}
                to={item.path}
                sx={{
                  backgroundColor:
                    location.pathname === item.path ? "white" : "inherit",
                  color: location.pathname === item.path ? "#9370DB" : "white",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor:
                      location.pathname === item.path
                        ? "white"
                        : "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === item.path ? "#9370DB" : "white",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>

      <Stack
        sx={{
          width: drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
          transition: "margin-left 0.3s ease-in-out",
          marginLeft: drawerOpen ? `${drawerWidth}px` : 0,
          padding: "30px",
        }}
        component="main"
      >
        <Toolbar /> {/* This will push the content below the AppBar */}
        <Outlet /> {/* This is where the nested routes will be rendered */}
      </Stack>
    </Box>
  );
}

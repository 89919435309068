import PropTypes from "prop-types";

import { Box, Typography } from "../../../Components";
import { IMAGES } from "../../../Assets";
import { AiFillPlayCircle } from "react-icons/ai";
import useWindowSizeHook from "../../../Components/WindowSizeHook";

export default function ReplyTemplate({ ...props }) {
	const { width } = useWindowSizeHook();
	// console.log("ggg", props);
	return (
		<div className="d-flex align-items-center justify-content-start my-4">
			{!props.loader && (
				<>
					<img
						src={
							props?.gender === "female"
								? IMAGES.FEMALE_DOCTOR_AVATAR
								: // : props?.gender === "male"
								  IMAGES.MALE_DOCTOR_AVATAR
							// : `https://ui-avatars.com/api/?name=${`${"Abid"} ${"Ali"}`}&background=fff&color=231671`
						}
						alt=""
						width={"60px"}
						height={"60px"}
						// width={props.gender === "female" ? "50px" : "60px"}
						// height={props.gender === "female" ? "60px" : "60px"}
						className="mx-2 rounded-circle"
						// style={{ borderRadius: props.gender === "female" ? "50%" : "0" }}
					/>
					<Box
						background="#ffffff"
						borderRadius="0.75rem 0.75rem 0.75rem 0"
						className="px-1 py-1"
						width="fit-content"
						maxWidth="50rem"
					>
						<Typography as={width < 600 ? "label" : "p"} className="m-2">
							{props?.reply?.description
								? props?.reply?.description?.replace(/<END_OF_TURN>/g, "")
								: props?.reply}
							{/* {props.reply} */}
						</Typography>
					</Box>
					{!props?.reply?.description && (
						<Box
							width="1.5rem"
							height="1.5rem"
							background="#9370DB"
							className="rounded-circle d-flex justify-content-center align-items-center mx-2 cursor-pointer"
							// onClick={() => {
							//   props?.resetInActiveTimer();
							//   props?.getAudio(
							//     props.reply,
							//     props?.gender === "female" ? 1 : 0
							//   );
							// }}
							// onClick={() => props?.speakMessage(props.reply, props?.gender)}
						>
							{/* {props?.videoPlaying ? (
                <AiFillPauseCircle
                  color="#ffffff"
                  size={20}
                  onClick={() => {
                    props?.setAudioBlob(null);
                    props?.setVideoPlaying(false);
                    props?.resetInActiveTimer();
                  }}
                />
              ) : ( */}
							<AiFillPlayCircle
								color="#ffffff"
								size={20}
								onClick={() => {
									props?.resetInActiveTimer();
									props?.getAudio(
										props.reply,
										props?.gender === "female" ? 1 : 0
									);
								}}
							/>
							{/* )} */}
						</Box>
					)}
				</>
			)}
		</div>
	);
}

ReplyTemplate.propTypes = {
	reply: PropTypes.string.isRequired,
};
